define("discourse/plugins/discourse-apim/discourse/components/usage-chart", ["exports", "@ember/component", "discourse/lib/load-script", "discourse-common/lib/debounce", "discourse-common/utils/decorators", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _loadScript, _debounce, _decorators, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    <canvas class="chart-canvas"></canvas>
  </div>
  */
  {
    "id": "wH2Jj04E",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[10,\"canvas\"],[14,0,\"chart-canvas\"],[12],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"canvas\"]]",
    "moduleName": "discourse/plugins/discourse-apim/discourse/components/usage-chart.hbs",
    "isStrictMode": false
  });
  class UsageChart extends _component.default {
    didInsertElement() {
      super.didInsertElement(...arguments);
      window.addEventListener("resize", this._resizeHandler);
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      window.removeEventListener("resize", this._resizeHandler);
      this._resetChart();
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      (0, _debounce.default)(this, this._scheduleChartRendering, 100);
    }
    _resizeHandler() {
      (0, _debounce.default)(this, this._scheduleChartRendering, 500);
    }
    static #_ = (() => dt7948.n(this.prototype, "_resizeHandler", [_decorators.bind]))();
    _scheduleChartRendering() {
      (0, _runloop.schedule)("afterRender", () => {
        if (!this.element) {
          return;
        }
        this._renderChart(this.data, this.element.querySelector(".chart-canvas"));
      });
    }
    _renderChart(model, chartCanvas) {
      if (!chartCanvas) {
        return;
      }
      const sorted = model.sortBy('month').reverse().slice(0, 12);
      const labels = sorted.map(_ref => {
        let {
          month
        } = _ref;
        return month;
      });
      const data = sorted.map(_ref2 => {
        let {
          count
        } = _ref2;
        return count;
      });
      const backgroundColor = getComputedStyle(document.body).getPropertyValue('--tertiary') ?? '#ffffff';
      const context = chartCanvas.getContext("2d");
      const chartConfig = {
        type: 'bar',
        data: {
          labels,
          datasets: [{
            data,
            label: 'Succesful API calls',
            fill: true,
            backgroundColor
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      };
      (0, _loadScript.default)("/javascripts/Chart.min.js").then(() => {
        this._resetChart();
        this._chart = new window.Chart(context, chartConfig);
      });
    }
    _resetChart() {
      this._chart?.destroy();
      this._chart = null;
    }
  }
  _exports.default = UsageChart;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UsageChart);
});